/* Navigation Styles */
@media (min-width: 360px) {
  nav {
    background-color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-left: 80px;
  }
}

@media (max-width: 360px) {
  nav {
    background-color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 80px;
    height: 40px;
  }
}

.linkName {
  padding-right: 20px;
  font-size: 21px;
  font-weight: bold;
}

.linkNameBtn {
  text-decoration: none;
  color: white;
  border: none;
  font-size: 14px;
  position: relative;
  right: 10px;
  &.active {
    color: #318df7;
  }
}

.linkNameBtn :ntn-child(1) {
  margin: 20px;
  padding: 20px;
}

.headerBotton {
  border: none;
  cursor: pointer;
  border-radius: 20px;
  position: relative;
  left: 10px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
}

.naviInputBox {
  position: relative;
  right: 10px;
}

.naviInput {
  width: 90px;
  height: 20px;
}

.icon {
  position: relative;
  top: 2px;
  size: 50;
  right: 35px;
  color: #333;
}

.user-actions {
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  right: 20px;
  padding-right: 20px;
}
