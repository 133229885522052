/* Footer Styles */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 11px;
  height: 20px;
}
