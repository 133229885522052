/* Main Content Styles */
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 360px) {
  .mainDiv {
    padding-bottom: 5px;
    margin: 0 auto;
  }
}

@media (max-width: 360px) {
  .mainDiv {
    /* border: 4px solid rgb(182, 155, 0); */
    padding-bottom: 5px;
    margin: 0 auto;
  }
}

.post-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.post {
  border-bottom: 1px solid rgb(0, 0, 0);
  margin: 0px 5px 0px 5px;
}

.post-content {
  display: flex;
  width: 100%;
  height: 80px;
}

.fileName {
  left: 10px;
  top: 25%;
  position: relative;
  text-align: left;
  width: 140px;

  flex-wrap: wrap;
}

.fileVersion {
  top: 40%;
  position: relative;
  right: 10px;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

.fileButton {
  position: relative;
  top: 35%;
  right: 15px;
  border: none;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
  font-family: "paybooc-Light", sans-serif;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  transition: 0.25s;
  background-color: #6aafe6;
  color: #ffffff;
}

.fileButton:active {
  transform: scale(1.2);
}

/* .w-btn {
  position: relative;
  border: none;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 15px;
  font-family: "paybooc-Light", sans-serif;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  font-weight: 600;
  transition: 0.25s;
}

.w-btn-blue {
  background-color: #6aafe6;
  color: #d4dfe6;
}

.w-btn:hover {
  letter-spacing: 2px;
  transform: scale(1.2);
  cursor: pointer;
}

.w-btn:active {
  transform: scale(1.5);
} */

.file-info-img {
  text-align: left;
  position: relative;
  width: 20%;
  height: 100%;
}

.file-info-name {
  text-align: left;
  position: relative;
  width: 50%;
  height: 100%;
}

.file-info-verson {
  text-align: left;
  position: relative;

  width: 20%;
  height: 100%;
}

.file-info-button {
  text-align: left;
  position: relative;

  width: 15%;
  height: 100%;
}

.fileImg {
  width: 50px;
  height: 50px;
  object-fit: fill;
  top: 20%;
  position: relative;
}
